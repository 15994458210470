.inputColor {
	background-color: rgb(255, 255, 255);
	border: solid 1px lightgrey;
	border-radius: 5px;
	max-width: 50%;
	padding: 2rem;
	margin: 1rem;
}

.inputBox {
	display: flex;
	justify-content: center;
	margin: 5px;
}
.inputItem {
	margin: 0 1rem;
}

.inputItem > p {
	margin: 0;
}
input {
	width: 150px;
	height: 50px;
}