* {
    margin: 0;
    padding: 0;
    font-family: 'Lato', serif;
}

.container {
    max-width: 900px;
    margin: 0 auto;
}

.row {
    display: flex;
}

.row > .col {
    padding: 0 20px;
}

.row > .col:first-child {
    padding-left: 0;
}

.row > .col:last-child {
    padding-right: 0;
    border-right: none;
}

.navSalles {
    width: 100%;
    margin: 1rem 1rem;
}

.setup-form {
    overflow-y: scroll;
    height: 50vh;
}

.setup-form .container {
    margin: 50px auto;
}

.form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.form-item label {
    margin-bottom: 5px;
}

input[type='text'] {
    padding: 5px;
    width: calc(100% - 10px);
    border: 1px solid #a1a1a1;
}

.preview {
    background: #e1e1e1;
    padding: 20px;
    height: calc(50vh - 40px);
}

.live-box {
    display: inline-block;
    padding: 30px;
    max-width: 1100px;
    background: #ffffff;
    border-top: 4px solid #000000;
    transform: scaleX(0);
    transform-origin: left;
    transition: 0.3s;
}

.live-box.show {
    transform: scaleX(1);
}

.live-ask {
    font-weight: bold;
    font-size: 1.6rem;
}

.live-box .col {
    border-right: 1px solid #a1a1a1;
}

.live-box .name {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 1.6rem;
    color: #000000;
}

.live-box .company {
    color: #a1a1a1;
    font-size: 1.1rem;
}

.streaming-view {
    position: absolute;
    bottom: 50px;
    left: 50px;
}

.bandeau-streaming {
    width: 30%;
    position: absolute;
    left: 44px;
    bottom: 155px;
}
